import React from "react"

import SEO from "../components/seo"
import FadeIn from "../components/Animated/FadeIn"
import ServicesSection from "../components/Services/ServicesSection"

const ServicesPage = () => {
  return (
    <FadeIn>
      <SEO
        title="Us | Who We Are"
        description="We are brand strategists, verbal designers, identity communicators & digital marketing experts."
      />
      <ServicesSection />
    </FadeIn>
  )
}

export default ServicesPage