import React from "react"

import * as styles from "./ServicesSection.module.scss"

const ServiceTextContainer = ({ service }) => {
  return (
    <div className={styles.serviceTextContainer}>
      <div className={styles.serviceTitle}>{service.title}</div>
      <div className={styles.serviceDescription}>{service.description}</div>
      {service.serviceTypes && service.serviceTypes.length > 0 && (
        <div className={styles.serviceTypesContainer}>
          {service.serviceTypes.map((serviceType, i) => (
            <div className={styles.serviceType}>{serviceType.title}</div>
          ))}
        </div>
      )}
    </div>
  )
}

export default ServiceTextContainer
