// extracted by mini-css-extract-plugin
export var hideForMobile = "ServicesSection-module--hide-for-mobile--sXA8g";
export var hideForTablet = "ServicesSection-module--hide-for-tablet--JMvqK";
export var hideForLaptop = "ServicesSection-module--hide-for-laptop--aE9Bi";
export var hideForDesktop = "ServicesSection-module--hide-for-desktop--G3V52";
export var hideForExtraLarge = "ServicesSection-module--hide-for-extra-large--cILDX";
export var showForMobileOnly = "ServicesSection-module--show-for-mobile-only--6ihYR";
export var showForTabletOnly = "ServicesSection-module--show-for-tablet-only--5loz6";
export var showForLaptopOnly = "ServicesSection-module--show-for-laptop-only--k5vqn";
export var showForDesktopOnly = "ServicesSection-module--show-for-desktop-only--pegFA";
export var showForExtraLargeOnly = "ServicesSection-module--show-for-extra-large-only--e-auj";
export var fullPageContainer = "ServicesSection-module--full-page-container--rGxM0";
export var container = "ServicesSection-module--container--D7DBG";
export var header = "ServicesSection-module--header--WtoeK";
export var mouseDownIcon = "ServicesSection-module--mouseDownIcon--r1gb+";
export var serviceContainer = "ServicesSection-module--serviceContainer--nOVao";
export var serviceTextContainer = "ServicesSection-module--serviceTextContainer--S0ZMX";
export var ServiceImageContainer = "ServicesSection-module--ServiceImageContainer--oOYzx";
export var serviceTitle = "ServicesSection-module--serviceTitle--mRhbP";
export var serviceDescription = "ServicesSection-module--serviceDescription--x8mti";
export var serviceTypesContainer = "ServicesSection-module--serviceTypesContainer--pXwup";
export var serviceType = "ServicesSection-module--serviceType--DDZzH";