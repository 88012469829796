import React, { useEffect } from "react"
import { motion, useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"

import * as styles from "./ServicesSection.module.scss"

const ServiceContainer = ({ children }) => {
  const controls = useAnimation()
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.25
  })

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [inView])

  return (
    <motion.div
      ref={ref}
      className={styles.serviceContainer}
      animate={controls}
      initial="hidden"
      variants={{
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 50 }
      }}
    >
      {children}
    </motion.div>
  )
}

export default ServiceContainer;