import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import * as styles from "./ServicesSection.module.scss"

const ServiceImageContainer = ({ image }) => {
  const gatsbyImage = getImage(image)

  return (
    <div className={styles.ServiceImageContainer}>
      <GatsbyImage
        image={gatsbyImage}
        loading="eager"
        className={styles.ServiceImage}
      />
    </div>
  )
}

export default ServiceImageContainer