import React, { useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import ServiceTextContainer from "./ServiceTextContainer"
import ServiceImageContainer from "./ServiceImageContainer"
import ServiceContainer from "./ServiceContainer"
import MouseDownIcon from "../../../assets/svgs/mouse-down.svg"
import loadable from "@loadable/component"
import * as serviceStyles from "../Service.module.scss"
import * as styles from "./ServicesSection.module.scss"

const SplitText = loadable(() => import("../../Animated/SplitText"))
const FadeUpAndIn = loadable(() => import("../../Animated/FadeUpAndIn"))

function AboutServicesSection() {
  const servicesSection = useRef()
  const {
    contentfulSettings: { servicePageBlocks },
  } = useStaticQuery(graphql`
    query ServicePageBlocksQuery {
      contentfulSettings {
        servicePageBlocks {
          id
          title
          description
          serviceTypes {
            title
          }
          image {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
      }
    }
  `)

  const scrollToRef = ref => e => {
    e.preventDefault()
    if (!ref) return false
    ref.current.scrollIntoView({
      behavior: "smooth",
    })
  }
  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <SplitText delay={1.8}>
            What do we actually do though?
          </SplitText>
        </div>
        <div
          className={styles.mouseDownIcon}
          onClick={scrollToRef(servicesSection)}
          aria-label="Go To Next Section"
        >
          <FadeUpAndIn delay={2.2}>
            <MouseDownIcon />
          </FadeUpAndIn>
        </div>
      </div>
      <div
        className={serviceStyles.sectionContainer}
        id="what-we-do"
        ref={servicesSection}
        style={{ overflow: "hidden" }}
      >
        {servicePageBlocks.map(service => {
          return (
            <ServiceContainer key={service.title}>
              <ServiceImageContainer image={service.image} />
              <ServiceTextContainer service={service} />
            </ServiceContainer>
          )
        })}
      </div>
    </>
  )
}

export default AboutServicesSection
