// extracted by mini-css-extract-plugin
export var hideForMobile = "Service-module--hide-for-mobile--B8KKO";
export var hideForTablet = "Service-module--hide-for-tablet--8yQaW";
export var hideForLaptop = "Service-module--hide-for-laptop--Cjd4f";
export var hideForDesktop = "Service-module--hide-for-desktop--n8CCJ";
export var hideForExtraLarge = "Service-module--hide-for-extra-large--ZGQ1U";
export var showForMobileOnly = "Service-module--show-for-mobile-only--IyfdW";
export var showForTabletOnly = "Service-module--show-for-tablet-only--ClMCM";
export var showForLaptopOnly = "Service-module--show-for-laptop-only--Qyjty";
export var showForDesktopOnly = "Service-module--show-for-desktop-only--n4n-f";
export var showForExtraLargeOnly = "Service-module--show-for-extra-large-only---oNQv";
export var fullPageContainer = "Service-module--full-page-container--crTwh";
export var sectionContainer = "Service-module--sectionContainer--ld-sy";